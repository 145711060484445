#root,
body,
html {
  height: 100%;
}

.sectionlist-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.section-container {
}

.section-header {
  display: flex;
  flex-direction: column;
}
